<template>
  <v-container fluid>
    <v-form class="pt-5">
      <v-row>
        <v-col cols="12">
          <informacoes-basicas
            id="informacoes-basicas-rel"
            :nomeCanvas.sync="canvas.nome"
          />
        </v-col>
      </v-row>
      <v-row id="row-parametros">
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-map-marker-plus-outline"
            inline
            class="px-5 py-3"
            style="min-height: 600px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Layers Personalizados
              </div>
            </template>
            <v-row>
              <v-col cols="12">
                <select-layers-table id="selecionar-layers" />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-map-outline"
            inline
            class="px-5 py-3"
            style="min-height: 600px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Visualização do mapa
              </div>
            </template>
            <v-row>
              <v-col cols="12">
                <preview-mapa id="visualizar-mapa" />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row id="row-btn-save">
        <v-col cols="12">
          <v-btn
            id="btn-save"
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="salvar()"
            :disabled="btnSaveDisabled"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CanvasService from '@/services/CanvasService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],

  components: {
    InformacoesBasicas: () => import('@/components/canvas/InformacoesBasicas'),
    SelectLayersTable: () => import('@/components/canvas/SelectLayersTable'),
    PreviewMapa: () => import('@/components/canvas/PreviewMapa')
  },

  data: () => ({
    canvas: {
      nome: ''
    },
    loading: false,
    arrTour: [
      {
        element: '#nome-mapa',
        intro:
          'Neste campo você pode informar um nome para seu mapa personalizado.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#selecionar-layers',
        intro:
          'Aqui você pode adicionar ou remover layers personalizados. Também é possível alterar a opacidade dos layers para melhorar a visualização no mapa.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#visualizar-mapa',
        intro:
          'Os layers serão plotados aqui, para que você possa ir visualizando como ficará seu mapa antes de salvá-lo.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ]
  }),

  mounted() {
    this.$store.dispatch('resetaCanvasConfig');

    if (this.$route.params.id) {
      this.getMapa(this.$route.params.id);
    }

    this.iniciarTourPagina();
  },

  computed: {
    layers() {
      return this.$store.getters.getCanvasConfigLayers;
    },

    layersIds() {
      return this.layers.map((layer) => layer.id);
    },

    opacidades() {
      return this.layers.map((layer) => layer.opacidade);
    },

    btnSaveDisabled() {
      return !(this.canvas.nome && this.layers.length);
    }
  },

  methods: {
    getMapa(id) {
      CanvasService.getCanvasById(id)
        .then((response) => {
          this.canvas = response.data.data;
          this.$store.dispatch('setCanvasConfigLayers', this.canvas.layers);
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar o mapa.', '', {
            position: 'topRight'
          })
        );
    },

    salvar() {
      this.loading = true;

      let data = {
        id: this.$route.params.id ? this.$route.params.id : null,
        nome: this.canvas.nome,
        config: {
          layers: this.layersIds,
          opacidades: this.opacidades
        }
      };

      CanvasService.save(data)
        .then(() => {
          this.$toast.success('Mapa salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['CANVAS_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar o mapa.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
