import APIService from './APIService';

export default {
  getCanvas() {
    return APIService.apiCall().get('/canvas/get-canvas');
  },
  getCanvasFavoritados() {
    return APIService.apiCall().get('/canvas/get-canvas-favoritados');
  },
  getCanvasById(id) {
    return APIService.apiCall().get(`/canvas/get-canvas-by-id/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post('/canvas/save', JSON.stringify(postData));
  },
  deletar(id) {
    return APIService.apiCall().get(`/canvas/excluir/${id}`);
  },
  duplicar(id) {
    return APIService.apiCall().get(`/canvas/duplicar/${id}`);
  },
  favoritar(id) {
    return APIService.apiCall().get(`/canvas/favoritar/${id}`);
  }
};
